// ********************************************************
// * Extend Foundation Typography with brand-specific needs
// *
// ********************************************************

@mixin napa-typography {

    a {
        font-weight: 700;

        &:not([class^="topnav-"]) {
            transition: $anchor-transition $anchor-transition-speed;
        }
    }

    a:hover,
    a:focus {
        text-decoration: none;
    }

    hr {
        max-width: none;
    }
}
